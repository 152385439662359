<template>
  <div class="ui-product">
    <header class="ui-product__header">
      <CRow class="align-items-center h-100">
        <CCol sm="auto" class="mt-2 mb-1 my-sm-0">
          <CButton color="outline-primary" size="sm" @click="onCancel()">
            <font-awesome-icon icon="arrow-left" />
          </CButton>
        </CCol>
        <CCol sm="auto" class="mt-2 mb-1 my-sm-0 mr-auto">
          <div class="ui-type-display-lg">{{ title() }}</div>
        </CCol>
        <CCol sm="auto" class="mt-1 mb-2 my-sm-0">
          <CButton color="outline-primary" class="mr-3" @click="onCancel()"
            >ANNULLA</CButton
          >
          <CButton color="primary" @click="onSave()">SALVA</CButton>
          <CButton color="danger" class="ml-3" v-if="showDelete" @click="onDelete()">ELIMINA</CButton>
        </CCol>
      </CRow>
    </header>
    <section class="ui-product__body">
      <ProductFormNav :errors="navSectionErrors" />

      <main class="ui-product__main">
        <div class="d-flex justify-content-center" v-if="loading">
          <CSpinner color="info" />
        </div>
        <div v-if="initialized">
          <CForm style="padding: 0 5px">
            <CSelect
              label="Lingua"
              :options="options.languages"
              :value="current_language"
              :horizontal="{ label: 'col-sm-auto', input: 'col-sm-auto' }"
              :disabled="isMode('create')"
              @update:value="onLanguageChange"
              custom
            />
          </CForm>
          <CCard class="card-bordered" id="prod-description">
            <CCardHeader>
              <div class="card-title">Descrizione del prodotto</div>
              <div class="card-subtitle">
                Inserisci le informazioni principali che saranno visibili al
                cliente finale
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="form-group">
                <label>{{
                  $t("models.productproducer.category__macrocategory_id")
                }}</label>

                <UiCInputRadioGroup
                  :checked.sync="product.category__macrocategory_id"
                  :options="options.category_macrocategory"
                  custom
                  inline
                />
                <div
                  class="invalid-feedback d-block"
                  v-if="!product.isValid('category__macrocategory_id')"
                >
                  {{ product.getErrorMessage(`category__macrocategory_id`) }}
                </div>
              </div>
              <CRow>
                <CCol md="8">
                  <CInput
                    :placeholder="$t('models.productproducer.name_info')"
                    v-model="product.translations[lang].name"
                    :isValid="product.isValid(`translations.${lang}.name`)"
                    :invalidFeedback="
                      product.getErrorMessage(`translations.${lang}.name`)
                    "
                  >
                    <template #label>
                      <strong>{{ $t("models.productproducer.name") }}</strong>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group">
                    <strong>{{
                      $t("models.productproducer.description")
                    }}</strong>
                    <CKEditor
                      :editor="editorMode"
                      v-model="product.translations[lang].description"
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CSelect
                    :value.sync="product.category__parent_id"
                    :options="options.category_parent"
                    :disabled="options.category_parent.length === 1"
                    :isValid="product.isValid('category__parent_id')"
                    :invalidFeedback="
                      product.getErrorMessage('category__parent_id')
                    "
                    custom
                  >
                    <template #label>
                      <strong>{{
                        $t("models.productproducer.category__parent_id")
                      }}</strong>
                      <em class="text-danger">*</em>
                    </template>
                  </CSelect>
                </CCol>
                <CCol md="6">
                  <CSelect
                    :value.sync="product.category_id"
                    :label="$t('models.productproducer.category_id')"
                    :options="options.category"
                    :disabled="options.category.length === 1"
                    :isValid="product.isValid('category_id')"
                    :invalidFeedback="product.getErrorMessage('category_id')"
                    custom
                  >
                    <template #label>
                      <strong>{{
                        $t("models.productproducer.category_id")
                      }}</strong>
                      <em class="text-danger">*</em>
                    </template>
                  </CSelect>
                </CCol>
              </CRow>

              <div class="form-group">
                <label>Adattato anche a una dieta</label>
                <div>
                  <CInputCheckbox
                    label="Senza glutine"
                    inline
                    custom
                    :checked.sync="product.extra.diet.gluten_free"
                  />
                  <CInputCheckbox
                    label="Vegana"
                    inline
                    custom
                    :checked.sync="product.extra.diet.vegan"
                  />
                  <CInputCheckbox
                    label="Vegetariana"
                    inline
                    custom
                    :checked.sync="product.extra.diet.vegetarian"
                  />
                  <CInputCheckbox
                    label="Senza lattosio"
                    inline
                    custom
                    :checked.sync="product.extra.diet.lactose_free"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Limitazioni</label>
                <CInputCheckbox
                  class="mb-3"
                  label="La vendita è vietata a minori"
                  custom
                  :checked.sync="product.age_class_limits"
                />
                <CSelect
                  v-if="product.age_class_limits"
                  :value.sync="product.ageclass_id"
                  :options="options.age_class"
                  :horizontal="{ label: 'col-sm-auto', input: 'col-sm-auto' }"
                  custom
                />
              </div>
            </CCardBody>
          </CCard>

          <CCard class="card-bordered" id="prod-logistics">
            <CCardHeader>
              <div class="card-title">Logistica</div>
              <div class="card-subtitle">
                Aiutaci e gestire nel modo migliore il tuo prodotto
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="form-group" v-if="options.logistic_type.length > 0">
                <label>Conservazione</label>
                <UiCInputRadioGroup
                  :checked.sync="product.ptype_id"
                  :options="options.logistic_type"
                  custom
                />
                <div
                  class="invalid-feedback d-block"
                  v-if="!product.isValid('ptype_id')"
                >
                  {{ product.getErrorMessage("ptype_id") }}
                </div>
              </div>
              <div class="form-group">
                <label>Consegna</label>
                <CInputCheckbox
                  :checked.sync="product.logistic_delivery_in_day"
                  label="Il prodotto deve essere consegnato in giornata es. pane"
                  name="logistic_delivery_in_day"
                  custom
                />
              </div>
            </CCardBody>
          </CCard>

          <CCard class="card-bordered" id="prod-certifications">
            <CCardHeader>
              <div class="card-title">Certificationi</div>
              <div class="card-subtitle">
                Valorizza il tuo prodotto inserendo tutte le certificazioni
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol
                  lg
                  sm="6"
                  class="my-1"
                  v-for="macro in options.certification_macrocategories"
                  :key="macro.value"
                >
                  <strong>
                    {{ macro.label }}
                  </strong>
                  <CInputCheckbox
                    v-for="option in filterCertificationByMacroctergory(macro)"
                    :key="option.value"
                    :label="option.label"
                    :checked="isCertificationChecked(option)"
                    @update:checked="onCertificationChange(option, $event)"
                    custom
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard class="card-bordered" id="prod-variants">
            <CCardHeader>
              <div class="card-title">Varianti</div>
              <div class="card-subtitle">
                Aggiungi le opzioni disponibili ad esempio i gusti o diversi
                tipi di confezioni
              </div>
            </CCardHeader>
            <CCardBody>
              <ProductFormVariants
                :variants="variants"
                :lang="lang"
                @onDelete="onDeleteVariant"
              />

              <CButton variant="outline" color="primary" @click="onAddVariant()"
                >AGGIUNGI VARIANTE</CButton
              >
            </CCardBody>
          </CCard>

          <CCard class="card-bordered" id="prod-price-weight">
            <CCardHeader>
              <div class="card-title">Peso e prezzo</div>
            </CCardHeader>
            <CCardBody>
              <div class="form-group">
                <LabelHelp
                  :required="true"
                  header="Help"
                  content="help description goes here..."
                >
                  Vendita
                </LabelHelp>
                <UiCInputRadioGroup
                  :checked.sync="product.extra.pw_option"
                  :options="options.pw"
                  custom
                  inline
                />
              </div>

              <CRow v-if="product.extra.pw_option === 3">
                <CCol md="4">
                  <CSelect
                    :value.sync="product.pw_unit"
                    :options="options.price_unit"
                    :isValid="product.isValid('pw_unit')"
                    :invalidFeedback="product.getErrorMessage('pw_unit')"
                    custom
                  >
                    <template #label>
                      <strong>Unità di misura</strong>
                      <em class="text-danger">*</em>
                    </template>
                  </CSelect>
                </CCol>
              </CRow>

              <div class="card-heading">Prezzo e peso</div>
              <CInputCheckbox
                class="mb-3"
                label="Gestisci prezzi diversi per ogni variante"
                :checked.sync="product.extra.flags.price_foreach_variant"
                custom
              />
              <div v-if="product.extra.flags.price_foreach_variant">
                <ProductFormVariantPrice
                  v-for="variant in variants"
                  :key="variant.id"
                  :variant="variant"
                  :lang="lang"
                  :taxOptions="options.tax"
                  :priceUnitOptions="options.price_unit"
                  :suffix="pwSuffix(variant)"
                  :showWeightFields="product.extra.pw_option !== 3"
                  :showName="true"
                />
              </div>

              <ProductFormVariantPrice
                v-else
                :variant="variant_main"
                :lang="lang"
                :taxOptions="options.tax"
                :priceUnitOptions="options.price_unit"
                :suffix="pwSuffix(variant_main)"
                :showWeightFields="product.extra.pw_option !== 3"
              />
              <!-- Quantità minima acquistabile -->
              <div class="card-heading">Quantità minima acquistabile</div>
              <CInputCheckbox
                class="mb-3"
                label="Gestisci quantità diverse per ogni variante"
                :checked.sync="
                  product.extra.flags.min_availability_foreach_variant
                "
                custom
              />
              <div v-if="product.extra.flags.min_availability_foreach_variant">
                <ProductFormVariantMinQuantity
                  v-for="variant in variants"
                  :key="variant.id"
                  :variant="variant"
                  :lang="lang"
                  :suffix="minQtySuffix(variant)"
                  :showName="true"
                />
              </div>
              <ProductFormVariantMinQuantity
                v-else
                :variant="variant_main"
                :lang="lang"
                :suffix="minQtySuffix(variant_main)"
              />
            </CCardBody>
          </CCard>

          <CCard class="card-bordered" id="prod-origin">
            <CCardHeader>
              <div class="card-title">Origine e deperibilità</div>
              <div class="card-subtitle">
                Dai una chiara indicazione della provenienza del prodotto
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="card-heading">Origine</div>
              <CInputCheckbox
                class="mb-3"
                label="Gestisci origini diverse per ogni variante"
                :checked.sync="product.extra.flags.origin_foreach_variant"
                custom
              />
              <div v-if="product.extra.flags.origin_foreach_variant">
                <ProductFormVariantOrigin
                  v-for="variant in variants"
                  :key="variant.id"
                  :variant="variant"
                  :lang="lang"
                  :productionTypeOptions="options.production_type"
                  :productionTypeNationsOptions="
                    options.production_type_nations
                  "
                  @onProductionTypeChange="onVariantProductionTypeChange"
                  :showName="true"
                />
              </div>
              <ProductFormVariantOrigin
                v-else
                :variant="variant_main"
                :lang="lang"
                :productionTypeOptions="options.production_type"
                :productionTypeNationOptions="options.production_type_nations"
                @onProductionTypeChange="onVariantProductionTypeChange"
              />

              <div class="card-heading">Deperibilità</div>
              <CInputCheckbox
                class="mb-3"
                label="Gestisci durate diverse per ogni variante"
                :checked.sync="
                  product.extra.flags.perishability_foreach_variant
                "
                custom
              />

              <div v-if="product.extra.flags.perishability_foreach_variant">
                <ProductFormVariantPerishability
                  v-for="variant in variants"
                  :key="variant.id"
                  :variant="variant"
                  :lang="lang"
                  :lastMinTOptions="options.last_min_t"
                  :showName="true"
                />
              </div>
              <ProductFormVariantPerishability
                v-else
                :variant="variant_main"
                :lang="lang"
                :lastMinTOptions="options.last_min_t"
              />
            </CCardBody>
          </CCard>

          <CCard class="card-bordered" id="prod-pictures">
            <CCardHeader>
              <div class="card-title">Foto</div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md>
                  <strong>Prodotto</strong>
                  <ProductFormUpload
                    @onSave="(data) => onImageSave(product, data)"
                    @onRemove="() => onImageRemove(product)"
                    title="Aggiungi immagine prodotto"
                    :image="product.image"
                    ratio="4:3"
                  />
                </CCol>
                <CCol md>
                  <strong>Etichetta</strong>
                  <ProductFormUpload
                    @onSave="(data) => onImageSave(product, data, true)"
                    @onRemove="() => onImageRemove(product, true)"
                    title="Aggiungi immagine etichetta"
                    :image="product.image_label"
                    ratio="free"
                  />
                </CCol>
              </CRow>

              <CInputCheckbox
                class="mt-3 mb-3"
                label="Aggiungi foto diverse per le varianti"
                :checked.sync="product.extra.flags.images_foreach_variant"
                custom
              />

              <div v-if="product.extra.flags.images_foreach_variant">
                <div
                  v-for="variant in variants"
                  :key="variant.id"
                  class="border-bottom pb-2 mb-2"
                >
                  <div class="ui-type-heading text-primary">
                    {{ variant.translations[lang].name }}
                  </div>
                  <CRow>
                    <CCol md>
                      <strong>Prodotto</strong>
                      <ProductFormUpload
                        @onSave="(data) => onImageSave(variant, data)"
                        @onRemove="() => onImageRemove(variant)"
                        title="Aggiungi immagine variante"
                        :image="variant.image"
                        ratio="4:3"
                      />
                    </CCol>
                    <CCol md>
                      <strong>Etichetta</strong>
                      <ProductFormUpload
                        @onSave="(data) => onImageSave(variant, data, true)"
                        @onRemove="() => onImageRemove(variant, true)"
                        title="Aggiungi immagine etichetta variante"
                        :image="variant.image_label"
                        ratio="free"
                      />
                    </CCol>
                  </CRow>
                </div>
              </div>
            </CCardBody>
          </CCard>

          <CCard class="card-bordered" id="prod-availability">
            <CCardHeader>
              <div class="card-title">Disponibilità</div>
            </CCardHeader>
            <CCardBody>
              <div class="card-heading">Quantità a magazzino</div>
              <CInputCheckbox
                class="mb-3"
                label="Gestisci quantità diverse per ogni variante"
                :checked.sync="
                  product.extra.flags.stock_availability_foreach_variant
                "
                custom
              />

              <div
                v-if="product.extra.flags.stock_availability_foreach_variant"
              >
                <ProductFormVariantAvailability
                  v-for="variant in variants"
                  :key="variant.id"
                  :variant="variant"
                  :lang="lang"
                  :availabilityLimitlessOptions="options.availability_limitless"
                  :availabilityXdOptions="options.availability_xd"
                  :suffix="pwSuffix(variant)"
                  :showName="true"
                />
              </div>
              <ProductFormVariantAvailability
                v-else
                :variant="variant_main"
                :lang="lang"
                :availabilityLimitlessOptions="options.availability_limitless"
                :availabilityXdOptions="options.availability_xd"
                :suffix="pwSuffix(variant_main)"
              />

              <div class="card-heading">Periodo di vendita</div>
              <CInputCheckbox
                class="mb-3"
                label="Gestisci periodi diversi per ogni variante"
                :checked.sync="
                  product.extra.flags.selltime_limited_foreach_variant
                "
                custom
              />
              <div v-if="product.extra.flags.selltime_limited_foreach_variant">
                <ProductFormVariantSellTime
                  v-for="variant in variants"
                  :key="variant.id"
                  :variant="variant"
                  :lang="lang"
                  :showName="true"
                />
              </div>
              <ProductFormVariantSellTime
                v-else
                :variant="variant_main"
                :lang="lang"
              />
            </CCardBody>
          </CCard>
        </div>
      </main>

      <ProductFormInfo
        :product="product"
        :variants="variants"
        :lang="lang"
        :showVariants="false"
        :showPreviewButton="mode === 'edit'"
        v-if="initialized"
      />
    </section>
  </div>
</template>

<script>
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isFunction from "lodash/isFunction";
import omit from "lodash/omit";
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../ds/index";
import LabelHelp from "../../../common/form/LabelHelp";
import UiCInputRadioGroup from "../../../common/form/UiCInputRadioGroup";
import ProductVariant from "../../../helpers/ProductVariant";
import Product from "../../../helpers/Product";
import { getOptionLabel } from "../../../helpers/common";
import { languageOptions } from "../../../config/global";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
  getMacroCategoryOptions,
  getParentCategoryOptions,
  getCategoryOptions,
  getAgeClassOptions,
  getLogisticTypeOptions,
  getCertificationsOptions,
  getCertificationsMacrocategoriesOptions,
  getProductionTypeOptions,
  getProductionTypeNationsOptions,
  getTaxIdOptions,
  getPwOptions,
  getLastMinTOptions,
  getPriceUnitOptions,
  getAvailabilityLimitlessOptions,
  getAvailabilityXdOptions,
} from "../../../helpers/options";
import {
  ProductFormInfo,
  ProductFormNav,
  ProductFormVariantAvailability,
  ProductFormVariantMinQuantity,
  ProductFormVariantOrigin,
  ProductFormVariantPerishability,
  ProductFormVariantPrice,
  ProductFormVariants,
  ProductFormVariantSellTime,
  ProductFormUpload,
} from "./partials";

export default {
  name: "ProductCreateForm",
  components: {
    CKEditor: CKEditor.component,
    ProductFormInfo,
    ProductFormNav,
    ProductFormVariantAvailability,
    ProductFormVariantMinQuantity,
    ProductFormVariantOrigin,
    ProductFormVariantPerishability,
    ProductFormVariantPrice,
    ProductFormVariants,
    ProductFormVariantSellTime,
    ProductFormUpload,
    LabelHelp,
    UiCInputRadioGroup,
  },

  data() {
    const lang = this.$i18n.locale;
    return {
      navSectionErrors: [
        {
          section: "description",
          error: false,
          fields: {
            product: [
              `translations.${lang}.name`,
              `category_id`,
              `category__parent_id`,
              `category__macrocategory_id`,
            ],
          },
        },
        {
          section: "logistics",
          error: false,
          fields: {
            product: [`ptype_id`],
          },
        },
        {
          section: "certifications",
          error: false,
          fields: {
            product: [`certifications`],
          },
        },
        {
          section: "variants",
          error: false,
          fields: {
            variants: [
              `translations.${lang}.name`,
              `price`,
              `price_sugg`,
              `tax_rate_id`,
              `price_len`,
              `price_unit`,
              `price_len_mol`,
              `pw`,
            ],
          },
        },
        {
          section: "price-weight",
          error: false,
          fields: {
            product: [`pw_unit`, `pw_min`],
          },
        },
        {
          section: "origin",
          error: false,
          fields: {
            variants: [
              `production_type_id`,
              `production_type_nation_id`,
              `last_min_n`,
              `last_min_t`,
            ],
          },
        },
        { section: "pictures", error: false },
        {
          section: "availability",
          error: false,
          fields: {
            variants: [
              `availability_limitless`,
              `availability_xd`,
              `valid_from`,
              `valid_to`,
            ],
          },
        },
      ],
      initialized: false,
      current_language: lang,
      mode: "create",
      loading: false,
      showDelete:false,
      product: null,
      variants: [],
      options: {
        languages: languageOptions,
        category_macrocategory: [],
        category_parent: [],
        category: [],
        age_class: [],
        logistic_type: [],
        certification_macrocategories: [],
        certification: [],
        pw: getPwOptions(this.$i18n),
        tax: [],
        production_type: [],
        production_type_nations: [],
        last_min_t: getLastMinTOptions(this.$i18n),
        price_unit: getPriceUnitOptions(this.$i18n),
        availability_limitless: getAvailabilityLimitlessOptions(),
        availability_xd: getAvailabilityXdOptions(),
      },
      editorMode: ClassicEditor,
      snapshot: null,
      translationsSnapshot: null,
    };
  },
  computed: {
    variant_main: function () {
      return this.variants[0];
    },
    lang: function () {
      return this.current_language;
    },
    currencySymbol: function () {
      return get(
        this.$store.state,
        "connections.current.family.wallets[0].get_currency_display_short"
      );
    },
    // showDelete: function(){
    //   console.log(this.base_prod)
    //   console.log(get(this.base_prod, "is_active"))
    //   if (this.base_prod) {
    //     if (get(this.base_prod, "is_active")==false){
    //       return true
    //     }
    //   }
    //   return false
    // }
  },

  async mounted() {
    await this.init();
  },

  watch: {
    "product.category__macrocategory_id": {
      handler: async function () {
        if (this.product === null) {
          return;
        }

        const lang = this.current_language;
        const { category__macrocategory_id, category__parent_id } =
          this.product;

        this.options.category_parent = [
          { value: null, label: "- seleziona -" },
          ...(await getParentCategoryOptions({
            lang,
            category__macrocategory_id: category__macrocategory_id,
          })),
        ];
        this.options.category = [
          { value: null, label: "- seleziona -" },
          ...(await getCategoryOptions({
            category__macrocategory_id,
            category__parent_id,
            lang,
          })),
        ];

        if (category__macrocategory_id) {
          this.options.logistic_type = await getLogisticTypeOptions({
            category__macrocategory_id,
            lang,
            reducer: (map, obj) => {
              map.push({
                value: obj.id,
                label: `${obj.translations[lang].name} - ${obj.translations[lang].description}`,
              });
              return map;
            },
          });
        }

        if (this.initialized) {
          this.product.category__parent_id = null;
          this.product.category_id = null;
          this.product.ptype_id = null;
        }
      },
      deep: true,
    },
    "product.category__parent_id": {
      handler: async function () {
        if (this.product === null) {
          return;
        }

        const lang = this.current_language;
        const { category__macrocategory_id, category__parent_id } =
          this.product;

        this.options.category = [
          { value: null, label: "- seleziona -" },
          ...(await getCategoryOptions({
            category__macrocategory_id,
            category__parent_id,
            lang,
          })),
        ];
        if (this.initialized) {
          this.product.category_id = null;
        }
      },
      deep: true,
    },
    "product.age_class_limits": {
      handler: function (newValue) {
        if (newValue === false && this.options.age_class.length > 0) {
          this.product.ageclass_id = this.options.age_class[0].value;
        }
      },
      deep: true,
    },
  },

  methods: {
    async init() {
      const self = this;
      this.initialized = false;
      this.loading = true;

      // reset
      this.product = null;
      this.variants = [];

      const lang = this.current_language;
      const producer_id = this.$store.state.role.producer__id;
      const role_id = this.$store.state.role.id;
      const product_id = this.$route.params.product_id;
      const from_product_id = this.$route.query.from_product_id;
      try {
        if (product_id) {
          this.mode = "edit";
          const data = await this.fetchProductData(role_id, product_id);
          // restore data
          const product = new Product(data);
          self.product = product;
          data.lots.forEach((variantData) => {
            const variant = new ProductVariant(variantData);
            self.variants.push(variant);
          });
        } else {
          this.mode = "create";
          if (from_product_id) {
            const data = await this.fetchProductData(role_id, from_product_id);
            const product = new Product(data);
            self.product = product;
            data.lots.forEach((variantData, index) => {
              const variant = new ProductVariant(variantData);
              if (index > 0) {
                variant.can_be_deleted = true;
              }
              self.variants.push(variant);
            });
          } else {
            this.product = new Product({}, producer_id);
            this.variants = [new ProductVariant()];
          }
        }

        this.options.category_macrocategory = await getMacroCategoryOptions({
          lang,
        });
        // this.product.category__macrocategory_id = this.options.category_macrocategory[0].value;

        this.options.category_parent = [
          { value: null, label: "- seleziona -" },
          ...(await getParentCategoryOptions({
            lang,
            category__macrocategory_id: this.product.category__macrocategory_id,
          })),
        ];
        this.options.category = [
          { value: null, label: "- seleziona -" },
          ...(await getCategoryOptions({
            category__macrocategory_id: this.product.category__macrocategory_id,
            category__parent_id: this.product.category__parent_id,
            lang,
          })),
        ];
        this.options.age_class = await getAgeClassOptions({ lang });

        // logistic
        if (this.product.category__macrocategory_id) {
          this.options.logistic_type = await getLogisticTypeOptions({
            category__macrocategory_id: this.product.category__macrocategory_id,
            lang,
          });
        }

        // certifications
        this.options.certification = await getCertificationsOptions();
        this.options.certification_macrocategories =
          await getCertificationsMacrocategoriesOptions();

        // variants
        this.options.tax = await getTaxIdOptions({
          country_id: this.$store.state.role.country_id,
        });

        // origin
        this.options.production_type = await getProductionTypeOptions({ lang });
        this.options.production_type_nations =
          await getProductionTypeNationsOptions();

        // fill variants defaults
        const tax_rate_id = this.options.tax[0].value;
        const price_unit = this.options.price_unit[0].value;
        const last_min_t = this.options.last_min_t[0].value;
        this.variants.forEach((variant) => {
          variant.tax_rate_id = variant.tax_rate_id || tax_rate_id;
          variant.price_unit = variant.price_unit || price_unit;
          variant.last_min_t = variant.last_min_t || last_min_t;
        });

        this.snapshot = this.getSnapshot();
        this.translationsSnapshot = this.getTranslationsSnapshot();

        this.initialized = true;
      } catch (e) {
        this.r;
        console.error(e);

        this.$store.dispatch("toaster/add", {
          title: "Attenzione!",
          text: "Errore durante il recupero dei dati necessari, si prega di riprovare",
          color: "info",
          autohide: true,
        });

        this.onCancel();
      }
      this.loading = false;
    },

    fetchProductData(role_id, product_id) {
      const dm = GetDataManagerNew(
        "role_producer_products_NEW",
        [role_id],
        product_id
      );
      
      
      return dm.executeQuery(new Query()).then((res) => {
        if (res.result.is_active){
          this.showDelete=false
        } else{
          this.showDelete=true
        }
        return res.result
      });
    },

    title() {
      return this.isMode("create")
        ? "Crea nuovo prodotto"
        : "Modifica prodotto";
    },

    isMode(mode) {
      return this.mode === mode;
    },

    isCertificationChecked(option) {
      const { value } = option;
      return this.product.certifications.includes(value);
    },

    onCertificationChange(option, checked) {
      const { value } = option;
      if (checked && !this.product.certifications.includes(value)) {
        this.product.certifications.push(value);
      } else if (!checked && this.product.certifications.includes(value)) {
        this.product.certifications = this.product.certifications.filter(
          (v) => v !== value
        );
      }
    },

    pwSuffix() {
      if (this.product.extra.pw_option === 3) {
        return `${this.currencySymbol} / ${
          this.product.pw_len
        } ${getOptionLabel(this.product.pw_unit, this.options.price_unit)}`;
      } else {
        return `${this.currencySymbol} / ${
          this.product.pw_len
        } ${getOptionLabel(
          this.product.extra.pw_option,
          this.options.pw,
          "singular"
        )}`;
      }
    },

    minQtySuffix() {
      if (this.product.extra.pw_option === 3) {
        return `${getOptionLabel(
          this.product.pw_unit,
          this.options.price_unit
        )}`;
      } else {
        return `${getOptionLabel(
          this.product.extra.pw_option,
          this.options.pw,
          "singular"
        )}`;
      }
    },

    filterCertificationByMacroctergory(macro) {
      return this.options.certification.filter(
        (c) => c.macro_id === macro.value
      );
    },

    onAddVariant() {
      const variant = new ProductVariant();

      // fill variants defaults
      const tax_rate_id = this.options.tax[0].value;
      const price_unit = this.options.price_unit[0].value;
      const last_min_t = this.options.last_min_t[0].value;
      variant.tax_rate_id = tax_rate_id;
      variant.price_unit = price_unit;
      variant.last_min_t = last_min_t;
      variant.can_be_deleted = true;
      variant.is_active = true;

      this.variants.push(variant);
    },

    onDeleteVariant(variant) {
      if (this.variants.length > 1) {
        this.variants = this.variants.filter((v) => v.id !== variant.id);
      }
    },

    onVariantProductionTypeChange(variant, value) {
      variant.production_type_id = value;
      const opt = this.options.production_type.find((p) => p.value == value);
      if (opt) {
        variant.production_type_need_nation = opt.need_nation;
      }
    },

    onCancel() {
      const areUsavedChanges = this.areUsavedChanges();
      this.askConfirm(areUsavedChanges, () => {
        this.$router.push({
          name: "RoleProductsList",
          params: { id: this.$store.state.role.id },
        });
      });
    },

    onImageSave(entity, { imageData }, label = false) {
      const keyPrefix = `image${label ? "_label" : ""}`;
      if (imageData) {
        entity[`${keyPrefix}`] = imageData;
      }
    },

    onImageRemove(entity, label = false) {
      const keyPrefix = `image${label ? "_label" : ""}`;
      entity[`${keyPrefix}`] = null;
    },

    prepareRequest(editMode = false, onlyLang = undefined) {
      const lots = [];
      const variant_main = cloneDeep(this.variant_main);
      const {
        price_foreach_variant,
        min_availability_foreach_variant,
        origin_foreach_variant,
        perishability_foreach_variant,
        stock_availability_foreach_variant,
        selltime_limited_foreach_variant,
      } = this.product.extra.flags;

      const isWeightProduct = this.product.isWeightProduct();

      this.variants.forEach((variant) => {
        if (variant.id !== variant_main.id) {
          if (!price_foreach_variant) {
            variant.price = variant_main.price;
            variant.price_sugg = variant_main.price_sugg;
            variant.tax_rate_id = variant_main.tax_rate_id;
          }

          if (!min_availability_foreach_variant) {
            variant.pw_len = variant_main.pw_len;
          }

          // if (!weight_foreach_variant) {
          if (!price_foreach_variant) {
            variant.price_len = variant_main.price_len;
            variant.price_unit = variant_main.price_unit;
            variant.price_len_mol = variant_main.price_len_mol;
          }

          if (!origin_foreach_variant) {
            variant.production_type_id = variant_main.production_type_id;
            variant.production_type_nation_id =
              variant_main.production_type_nation_id;
          }

          if (!perishability_foreach_variant) {
            variant.last_min_n = variant_main.last_min_n;
            variant.last_min_t = variant_main.last_min_t;
          }

          if (!stock_availability_foreach_variant) {
            variant.availability_limitless =
              variant_main.availability_limitless;
            variant.availability_start = variant_main.availability_start;
            variant.availability_xd = variant_main.availability_xd;
          }

          if (!selltime_limited_foreach_variant) {
            variant.valid_limited = variant_main.valid_limited;
            variant.valid_from = variant_main.valid_from;
            variant.valid_to = variant_main.valid_to;
          }
        }

        lots.push({ ...variant.prepare(editMode, onlyLang, isWeightProduct) });
      });

      return {
        ...this.product.prepare(editMode, onlyLang),
        lots: lots,
      };
    },

    setNavSectionErrors(fieldErrors) {
      const productErrors = omit(fieldErrors, "lots");
      const variantsErrors = get(fieldErrors, "lots", []);

      for (const section of this.navSectionErrors) {
        // product
        if (Object.keys(productErrors).length > 0) {
          const productFields = get(section, "fields.product", []);
          if (productFields.length > 0) {
            for (const f of productFields) {
              const e = get(productErrors, f, null);
              if (e !== null) {
                section.error = true;
                break;
              }
            }
          }
        }

        if (section.error === true) {
          continue;
        }

        // variants
        if (variantsErrors.length > 0) {
          const variantFields = get(section, "fields.variants", []);
          if (variantFields.length > 0) {
            ploop: for (const variant of variantsErrors) {
              for (const f of variantFields) {
                const e = get(variant, f, null);
                if (e !== null) {
                  section.error = true;
                  break ploop;
                }
              }
            }
          }
        }
      }
    },

    resetNavSectionErrors() {
      this.navSectionErrors = this.navSectionErrors.map((section) => {
        return { ...section, error: false };
      });
    },
    onDelete() {
      const self=this
      if (confirm("Vuoi davvero elminare il prodotto definitivamente?")) {
          const dm = GetDataManagerNew("role_producer_products_NEW", [
            this.$store.state.role.id,
          ]);
          dm.remove("", this.product.id)
          .then((response) => {
            self.$store.dispatch("toaster/add", {
              title: "OK!",
              text: "Prodotto eliminato con successo",
              color: "success",
              autohide: true,
            });
            this.$router.push({
              name: "RoleProductsList",
              params: { id: this.$store.state.role.id },
            });
          })
          .catch((response) => {
            self.$store.dispatch("toaster/add", {
              title: "Attenzione!",
              text: "Cancellazione non riuscita!",
              color: "info",
              autohide: true,
            })
          });
        }
    },
    onSave() {
      const self = this;
      const lang = this.current_language;

      this.product.resetErrors();
      this.variants.forEach((variant) => {
        variant.resetErrors();
      });

      const handleRequestErrors = (response) => {
        const errors = JSON.parse(response[0].error.response);
        console.error(errors.errors);
        const { fields } = errors.errors;

        self.setNavSectionErrors(fields);

        self.product.setErrors(fields);
        if (fields.lots) {
          self.variants.forEach((variant, idx) => {
            const variantErrors = fields.lots[idx];
            if (variantErrors !== undefined) {
              variant.setErrors(variantErrors);
            }
          });
        }
        self.$store.dispatch("toaster/add", {
          title: "Attenzione!",
          text: "Si prega di controllare i dati inseriti e riprovare",
          color: "info",
          autohide: true,
        });
      };

      self.resetNavSectionErrors();

      const dm = GetDataManagerNew("role_producer_products_NEW", [
        this.$store.state.role.id,
      ]);
      if (this.mode === "create") {
        const requestParams = this.prepareRequest(false, lang);
        dm.insert(requestParams)
          .then((response) => {
            self.$store.dispatch("toaster/add", {
              title: "OK!",
              text: "Prodotto aggiunto con successo",
              color: "success",
              autohide: true,
            });

            self.$router.push({
              name: "RoleProductsEdit",
              params: {
                id: self.$store.state.role.id,
                product_id: response.id,
              },
            });
          })
          .catch(handleRequestErrors);
      } else {
        const requestParams = this.prepareRequest(true, lang);
        dm.update("", requestParams)
          .then((response) => {
            self.$store.dispatch("toaster/add", {
              title: "OK!",
              text: "Prodotto aggiornato con successo",
              color: "success",
              autohide: true,
            });

            // Aggiorna id varianti con id ricevuti dal server in caso in cui una nuova variante sia stata creata
            self.variants = response.lots.reduce((acc, variantData) => {
              const variant = new ProductVariant(variantData);
              acc.push(variant);
              return acc;
            }, []);
            if (response.is_active){
              this.showDelete=false
            }else{
              this.showDelete=true
            }
            self.snapshot = self.getSnapshot();
            self.translationsSnapshot = self.getTranslationsSnapshot();
          })
          .catch(handleRequestErrors);
      }
    },

    getSnapshot() {
      return {
        product: this.product.snapshot(),
        variants: this.variants.map((variant) => variant.snapshot()),
      };
    },

    getTranslationsSnapshot() {
      return {
        product: this.product.translationsSnapshot(),
        variants: this.variants.map((variant) =>
          variant.translationsSnapshot()
        ),
      };
    },

    askConfirm(condition, callback) {
      if (!isFunction(callback)) {
        return;
      }
      const message =
        "Attenzione! Le modifiche non salvate andranno perse. Si desidera procedere comunque?";
      if (condition) {
        if (confirm(message)) {
          callback.apply(this);
        }
      } else {
        callback.apply(this);
      }
    },

    areUsavedChanges() {
      const currentSnaphost = this.getSnapshot();
      return JSON.stringify(currentSnaphost) !== JSON.stringify(this.snapshot);
    },

    areUsavedTranslations() {
      const currentSnaphost = this.getTranslationsSnapshot();
      return (
        JSON.stringify(currentSnaphost) !==
        JSON.stringify(this.translationsSnapshot)
      );
    },

    onLanguageChange(value) {
      const areUsavedChanges = this.areUsavedTranslations();
      this.askConfirm(areUsavedChanges, () => {
        this.current_language = value;
      });
    },
  },
};
</script>
