import { get, pick } from "lodash";
import { languageOptions } from "../config/global";
import { isValidDate, formatDate } from "./common";
import Product from "./Product";

const fillEmptyTranslations = (trans = {}) => {
  const translations = {};
  languageOptions.forEach((item) => {
    const lang = item.value;
    translations[lang] = {
      name: get(trans, `${lang}.name`, ""),
      slug: get(trans, `${lang}.slug`, ""),
      description: get(trans, `${lang}.description`, ""),
    };
  });
  return translations;
};

const prepareTranslations = (translations, onlyLang) => {
  let newTranslations = Object.assign({}, translations);

  if (onlyLang) {
    newTranslations = pick(newTranslations, onlyLang);
  }

  return newTranslations;
};

class ProductVariant {
  constructor(data = {}) {
    this.errors = {};
    const productData = get(data, "productproducer", null);
    this.id = get(data, "id", new Date().getTime());
    this.productproducer = productData ? new Product(productData) : {};
    this.translations = fillEmptyTranslations(get(data, "translations", {}));
    this.producer_code = get(data, "producer_code", "");
    this.is_active = get(data, "is_active", true);
    this.availability_limitless = get(data, "availability_limitless", true);
    this.availability_start = get(data, "availability_start", 1);
    this.availability_over = get(data, "availability_over", false);
    this.availability_xd = get(data, "availability_xd", false);
    this.availability = get(data, "availability", 0);
    this.production_type_id = get(data, "production_type_id", false);
    this.production_type_nation_id = get(
      data,
      "production_type_nation_id",
      null
    );
    this.production_type_need_nation = this.production_type_nation_id !== null;
    this.tax_rate_id = get(data, "tax_rate_id", null);
    this.price = get(data, "price", 0);
    this.price_sugg = get(data, "price_sugg", 0);
    this.price_len = get(data, "price_len", 1);
    this.price_unit = get(data, "price_unit", 1);
    this.price_len_mol = get(data, "price_len_mol", false);
    this.pw = get(data, "pw", false); // fattura a peso
    this.pw_min = get(data, "pw_min", null);
    this.last_min_n = get(data, "last_min_n", null);
    this.last_min_t = get(data, "last_min_t", null);

    this.valid_from = get(data, "valid_from", null)
      ? new Date(get(data, "valid_from", null))
      : null;
    this.valid_to = get(data, "valid_to", null)
      ? new Date(get(data, "valid_to", null))
      : null;
    this.valid_limited =
      this.valid_from === null && this.valid_to === null ? "always" : "limited";

    this.image = get(data, "image", null);
    this.image_label = get(data, "image_label", null);

    this.can_be_deleted = false;
  }

  prepare(editMode = false, onlyLang = undefined, isWeightProduct = false) {
    const data = {
      producer_code: this.producer_code,
      is_active: this.is_active,
      availability_limitless: this.availability_limitless,
      availability_start: this.availability_start,
      availability_over: this.availability_over,
      availability_xd: this.availability_xd,
      availability: this.availability,
      production_type_id: this.production_type_id,
      production_type_need_nation: this.production_type_need_nation,
      production_type_nation_id: this.production_type_nation_id,
      tax_rate_id: this.tax_rate_id,
      price: this.price,
      price_sugg: this.price_sugg,
      price_len: !isWeightProduct ? this.price_len : null,
      price_unit: !isWeightProduct ? this.price_unit : null,
      price_len_mol: !isWeightProduct ? this.price_len_mol : null,
      pw: this.pw,
      pw_min: this.pw_min,
      last_min_n: this.last_min_n || null,
      last_min_t: this.last_min_n ? this.last_min_t : null,
      valid_limited: this.valid_limited,
      valid_from:
        this.valid_limited === "limited" && isValidDate(this.valid_from)
          ? formatDate(new Date(this.valid_from), "yyyy-MM-dd")
          : null,
      valid_to:
        this.valid_limited === "limited" && isValidDate(this.valid_to)
          ? formatDate(new Date(this.valid_to), "yyyy-MM-dd")
          : null,
      translations: prepareTranslations(this.translations, onlyLang),
    };

    data.image = this.image !== null ? parseInt(this.image.id, 10) : null;
    data.image_label =
      this.image_label !== null ? parseInt(this.image_label.id, 10) : null;

    if (editMode) {
      data.id = this.id;
    }

    return data;
  }

  setErrors(errors) {
    this.errors = Object.assign({}, errors);
    return this.errors;
  }

  resetErrors() {
    this.errors = Object.assign({});
  }

  isValid(key) {
    const s =
      get(this.errors, key, undefined) !== undefined ? false : undefined;
    return s;
  }

  getErrorMessage(key) {
    const val = get(this.errors, key, undefined);
    if (val !== undefined) {
      return Array.isArray(val) ? val.join(" ") : val;
    }
    return "";
  }

  snapshot() {
    const snapshot = {
      id: this.id,
      productproducer: this.productproducer,
      translations: this.translations,
      producer_code: this.producer_code,
      is_active: this.is_active,
      availability_limitless: this.availability_limitless,
      availability_start: this.availability_start,
      availability_over: this.availability_over,
      availability_xd: this.availability_xd,
      availability: this.availability,
      production_type_id: this.production_type_id,
      production_type_nation_id: this.production_type_nation_id,
      production_type_need_nation: this.production_type_need_nation,
      tax_rate_id: this.tax_rate_id,
      price: this.price,
      price_sugg: this.price_sugg,
      price_len: this.price_len,
      price_unit: this.price_unit,
      price_len_mol: this.price_len_mol,
      pw: this.pw,
      pw_min: this.pw_min,
      last_min_n: this.last_min_n,
      last_min_t: this.last_min_t,
      valid_from: this.valid_from,
      valid_to: this.valid_to,
      valid_limited: this.valid_limited,
      image: this.image,
      image_label: this.image_label,
      can_be_deleted: this.can_be_deleted,
    };

    return JSON.parse(JSON.stringify(snapshot));
  }

  translationsSnapshot() {
    const snapshot = {
      translations: this.translations,
    };

    return JSON.parse(JSON.stringify(snapshot));
  }
}

export default ProductVariant;
